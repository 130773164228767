// const url = "http://172.16.1.145:7010";

const url = "https://java-project.mobiloitte.org";

const user = `${url}/account`;
const auth = `${url}/auth`;
const account = `${url}/account`;
const wallet = `${url}/wallet`;
const static1 = `${url}/static`;
const admin = `${url}/wallet/admin`;
const notification = `${url}/notification`;

const ApiConfig = {
  userLogin: `${auth}`,
  getCoinList: `${wallet}/coin/get-coin-list`,
  userSignUp: `${user}/signup`,
  verifyOTP: `${user}/verify-user`,
  resendOTP: `${user}/resend-verify-email`,
  forgotPassword: `${user}/forget-password/mobiloitApp`, ///verify-sms-code-mobile-app
  verifyOTPSMS: `${user}/verify-sms-code-mobile-app`, //verify-sms-code-mobile-app
  resetPassword: `${user}/reset-password-mobile-app`,
  getKYCDetails: `${account}/get-kyc-details`,
  // editUserProfile: `${user}/profile-update`,
  getCountryList: `${user}/get-country-list`,
  getTransactionHistory: `${url}/wallet/wallet/get-all-transaction-history`,

  addSubscribe: `${user}/add-subscribe`,

  // account

  myAccount: `${account}/my-account`,
  userBankAccount: `${account}/add-user-bank-account`,
  userAccountDetails: `${account}/get-user-account-detail`,
  userAccountList: `${account}/get-user-account-list`,
  userAccountDelete: `${account}/delete-user-bank-account`,
  saveKycDetails: `${account}/save-kyc-details`,
  uploadFile: `${account}/upload-file`,
  getKycList: `${account}/get-all-kyc-details`,
  editUserProfile: `${account}/profile-update`,
  CountryList: `${account}/get-country-list`,
  searhList: `${account}/search-and-filter-bank-account-list`,
  newsletter: `${account}/get-all-news-letter-for-website`,

  // wallet

  myWallet: `${wallet}/get-all-user-balance-and-coinlist`,
  getAllCoinWithWalletAddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,
  toaddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,
  // static
  contactUS: `${static1}/submit-support-ticket`,
  postnewsLetter: `${static1}/post-news-letter-to-user`,
  // Aboutus: `${static1}/get-all-static-content-data`,

  faq: `${static1}/search-filter-faq-list?page=0&pageSize=10`,
  term: `${static1}/get-static-page-data-by-page-key?pageKey=Terms_And_Conditions`,
  Aboutus: `${static1}/get-static-page-data-by-page-key?pageKey=About_Us`,
  // policy: `${static1}/get-static-page-data-by-page-key?pageKey=Privacy_Policy`,
  policy: `${static1}/get-all-static-content-details-for-web`,
  myWallet: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getCurrentprice: `${wallet}/wallet/get-price?`,

  myAddress: `${wallet}/wallet/get-address-new`,
  withdraw: `${wallet}/wallet/withdraw`,
  approveWithdraw: `${wallet}/wallet/approve-withdraw`,

  cancelWithdraw: `${wallet}/wallet/cancel-withdraw`,
  deposits: `${wallet}/wallet/get-deposits`,
  buyToken: `${wallet}/basic-exchange/buy-token`,
  buyTokenBtc: `${wallet}/wallet/buy-token`,
  // admin
  transactionHistoy: `${wallet}/get-all-transaction-history`,

  // notification
  getNotification: `${notification}/get-notification-data`,
  readNotification: `${notification}/read-notification`,
  clearNotification: `${notification}/clear-notification`,

  // account
};

export default ApiConfig;
